import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  // Route,
  Redirect,
} from 'react-router-dom';
import loadable from '@loadable/component';

import { getEnvVars } from 'helpers/common';
import Route from './Route';
import withProtectedRoute from './withProtectedRoute';
import {
  checkHasNoBuyCampaigns,
  checkLoggedIn,
  checkLoggedInAndVerified,
} from './protectedDecorators';

const { REACT_APP_DISABLE_LEADS, REACT_APP_DISABLE_SELL } = getEnvVars();

const AuthPage = loadable(() =>
  import(/* webpackChunkName: "AuthPage" */ 'views/AuthPage'),
);

const Home = loadable(() =>
  import(/* webpackChunkName: "Home" */ 'views/Home'),
);

const StyleSystem = loadable(() =>
  import(/* webpackChunkName: "StyleSystem" */ 'views/StyleSystem'),
);

const TermsOfUse = loadable(() =>
  import(/* webpackChunkName: "TermsOfUse" */ 'views/TermsOfUse'),
);

const AboutUs = loadable(() =>
  import(/* webpackChunkName: "AboutUs" */ 'views/AboutUs'),
);

const PrivacyPolicy = loadable(() =>
  import(/* webpackChunkName: "Privacy" */ 'views/PrivacyPolicy'),
);

const PrivacyAgreement = loadable(() =>
  import(/* webpackChunkName: "Privacy" */ 'views/PrivacyAgreement'),
);

const VulnerabilityPolicy = loadable(() =>
  import(
    /* webpackChunkName: "VulnerabilityPolicy" */ 'views/VulnerabilityPolicy'
  ),
);

const CookiePolicy = loadable(() =>
  import(/* webpackChunkName: "CookiePolicy" */ 'views/CookiePolicy'),
);

const AccessibilityStatement = loadable(() =>
  import(/* webpackChunkName: "CookiePolicy" */ 'views/AccessibilityStatement'),
);

const GetHelp = loadable(() =>
  import(/* webpackChunkName: "GetHelp" */ 'views/GetHelp'),
);

const BuyClicksCreateCampaign = loadable(() =>
  import(
    /* webpackChunkName: "BuyClicksCreateCampaign" */ 'views/BuyClicksCreateCampaign'
  ),
);

const BuyOverview = loadable(() =>
  import(/* webpackChunkName: "BuyOverview" */ 'views/BuyOverview'),
);

// const BuyAnalytics = loadable(() =>
//   import(/* webpackChunkName: "BuyClicksAnalytics" */ 'views/BuyAnalytics'),
// );

const BuyClicksWelcomePage = loadable(() =>
  import(
    /* webpackChunkName: "BuyClicksWelcomePage" */ 'views/BuyClicksWelcomePage'
  ),
);

const BuyLeadsWelcomePage = loadable(() =>
  import(
    /* webpackChunkName: "BuyLeadsWelcomePage" */ 'views/BuyLeadsWelcomePage'
  ),
);

const SellLeadsWelcomePage = loadable(() =>
  import(
    /* webpackChunkName: "SellLeadsWelcomePage" */ 'views/SellLeadsWelcomePage'
  ),
);

// const BuyClicksCategoryCampaigns = loadable(() =>
//   import(
//     /* webpackChunkName: "BuyClicksCategoryCampaigns" */ 'views/BuyClicksCategoryCampaigns'
//   ),
// );

const BuyCampaignsImport = loadable(() =>
  import(
    /* webpackChunkName: "BuyClicksImport" */ 'views/BuyCampaignsImportForm'
  ),
);

// const BuyClicksCampaignLocations = loadable(() =>
//   import(
//     /* webpackChunkName: "BuyClicksCampaignLocations" */ 'views/BuyClicksCampaignLocations'
//   ),
// );

const BuyClicksEditCampaign = loadable(() =>
  import(
    /* webpackChunkName: "BuyClicksEditCampaign" */ 'views/BuyClicksEditCampaign'
  ),
);

const BuyClicksEditGeography = loadable(() =>
  import(
    /* webpackChunkName: "BuyClicksEditGeography" */ 'views/BuyClicksEditGeography'
  ),
);

const BuyClicksEditSpecifications = loadable(() =>
  import(
    /* webpackChunkName: "BuyClicksEditSpecifications" */ 'views/BuyClicksEditSpecifications'
  ),
);

const SellLeadsOverview = loadable(() =>
  import(/* webpackChunkName: "SellLeadsOverview" */ 'views/SellLeadsOverview'),
);

const BuyLeadsLeads = loadable(() =>
  import(/* webpackChunkName: "BuyLeadsLeads" */ 'views/BuyLeadsLeads'),
);

const BuyLeadsDetails = loadable(() =>
  import(/* webpackChunkName: "BuyLeads" */ 'views/BuyLeadsDetails'),
);

const BuyCampaigns = loadable(() =>
  import(/* webpackChunkName: "BuyCampaigns" */ 'views/BuyCampaigns'),
);

const BuyCampaignGroup = loadable(() =>
  import(/* webpackChunkName: "BuyCampaigns" */ 'views/BuyCampaignGroup'),
);

// const BuyClicksCategories = loadable(() =>
//   import(
//     /* webpackChunkName: "BuyClicksCategories" */ 'views/BuyClicksCategories'
//   ),
// );

const BuyClicksClicks = loadable(() =>
  import(/* webpackChunkName: "BuyClicksClicks" */ 'views/BuyClicksClicks'),
);

const DuplicateCampaign = loadable(() =>
  import(/* webpackChunkName: "DuplicateCampaign" */ 'views/DuplicateCampaign'),
);

const DuplicateGroup = loadable(() =>
  import(/* webpackChunkName: "DuplicateGroup" */ 'views/DuplicateGroup'),
);

const BuyCampaignDetail = loadable(() =>
  import(/* webpackChunkName: "BuyCampaignDetail" */ 'views/BuyCampaignDetail'),
);
const BuyLeadsCampaignDetailAllLocations = loadable(() =>
  import(
    /* webpackChunkName: "BuyLeadsCampaignDetailAllLocations" */ 'views/BuyLeadsCampaignDetailAllLocations'
  ),
);

const BuyLeadsCreateCampaign = loadable(() =>
  import(
    /* webpackChunkName: "BuyLeadsCreateCampaign" */ 'views/BuyLeadsCreateCampaign'
  ),
);

const BuyLeadsEditCampaign = loadable(() =>
  import(
    /* webpackChunkName: "BuyLeadsEditCampaign" */ 'views/BuyLeadsEditCampaign'
  ),
);

const SellLeadsCampaigns = loadable(() =>
  import(
    /* webpackChunkName: "SellLeadsCampaigns" */ 'views/SellLeadsCampaigns'
  ),
);

const SellLeadsCampaignDetail = loadable(() =>
  import(
    /* webpackChunkName: "SellLeadsCampaignDetail" */ 'views/SellLeadsCampaignDetail'
  ),
);

const SetupBilling = loadable(() =>
  import(/* webpackChunkName: "SetupBilling" */ 'views/SetupBilling'),
);

const SetupBillingActive = loadable(() =>
  import(
    /* webpackChunkName: "SetupBillingActive" */ 'views/SetupBillingActive'
  ),
);

const NotFound = loadable(() =>
  import(/* webpackChunkName: "NotFound" */ 'views/NotFound'),
);

const AccountOverview = loadable(() =>
  import(/* webpackChunkName: "AccountOverview" */ 'views/AccountOverview'),
);

const AccountEdit = loadable(() =>
  import(/* webpackChunkName: "AccountEdit" */ 'views/AccountEdit'),
);

const AccountBilling = loadable(() =>
  import(/* webpackChunkName: "AccountBilling" */ 'views/AccountBilling'),
);

const AccountBillingEdit = loadable(() =>
  import(
    /* webpackChunkName: "AccountBillingEdit" */ 'views/AccountBillingEdit'
  ),
);

const AccountDayPartingEdit = loadable(() =>
  import(
    /* webpackChunkName: "AccountDayPartingEdit" */ 'views/AccountDayPartingEdit'
  ),
);

const AccountBudgetEdit = loadable(() =>
  import(/* webpackChunkName: "AccountBudgetEdit" */ 'views/AccountBudgetEdit'),
);

const FinancesOverview = loadable(() =>
  import(/* webpackChunkName: "FinancesOverview" */ 'views/FinancesOverview'),
);

const FinancesPurchases = loadable(() =>
  import(/* webpackChunkName: "FinancesPurchases" */ 'views/FinancesPurchases'),
);

const FinancesStatements = loadable(() =>
  import(
    /* webpackChunkName: "FinancesStatements" */ 'views/FinancesStatements'
  ),
);

const TableauStats = loadable(() =>
  import(/* webpackChunkName: "TableauStats" */ 'views/TableauStats'),
);

const DevCreateBuyer = loadable(() =>
  import(/* webpackChunkName: "DevCreateBuyer" */ 'views/_DevCreateBuyer'),
);

export const routes = [
  {
    path: '/',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(Home),
  },
  {
    path: '/buy',
    exact: true,
    component: () => <Redirect to="/" />,
  },
  !REACT_APP_DISABLE_LEADS && {
    path: '/buy/leads',
    exact: true,
    component: () => <Redirect to="/buy/leads/overview" />,
  },
  {
    path: '/buy/clicks',
    exact: true,
    component: () => <Redirect to="/buy/clicks/overview" />,
  },
  // {
  //   path: '/sell',
  //   exact: true,
  //   component: () => <Redirect to="/sell/leads/overview" />,
  // },
  // {
  //   path: '/sell/leads',
  //   exact: true,
  //   component: () => <Redirect to="/sell/leads/overview" />,
  // },
  {
    path: '/auth/:urlAction',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedInAndVerified();
    })(AuthPage),
  },
  {
    path: '/auth/:urlAction/:uid/:token',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedInAndVerified();
    })(AuthPage),
  },
  {
    path: '/auth',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedInAndVerified();
    })(() => <Redirect to="/auth/login" />),
  },
  {
    path: '/stylesystem',
    exact: true,
    component: StyleSystem,
  },
  !REACT_APP_DISABLE_LEADS && {
    path: '/buy/leads/create-campaign',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(BuyLeadsCreateCampaign),
  },
  !REACT_APP_DISABLE_LEADS && {
    path: '/buy/leads/edit-campaign',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(BuyLeadsEditCampaign),
  },
  !REACT_APP_DISABLE_LEADS && {
    path: '/buy/leads/overview',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(BuyOverview),
  },
  !REACT_APP_DISABLE_LEADS && {
    path: '/buy/leads/leads',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(BuyLeadsLeads),
  },
  // !REACT_APP_DISABLE_LEADS && {
  //   path: '/buy/leads/analytics',
  //   exact: true,
  //   component: withProtectedRoute(() => {
  //     return checkLoggedIn();
  //   })(BuyAnalytics),
  // },
  !REACT_APP_DISABLE_LEADS && {
    path: '/buy/leads/leads/:id',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(BuyLeadsDetails),
  },
  !REACT_APP_DISABLE_LEADS && {
    path: '/buy/leads/campaigns',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(BuyCampaigns),
  },
  !REACT_APP_DISABLE_LEADS && {
    path: '/buy/leads/duplicate-campaign/:campaignId',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(DuplicateCampaign),
  },
  !REACT_APP_DISABLE_LEADS && {
    path: '/buy/leads/campaigns/:groupId',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(BuyCampaignGroup),
  },
  !REACT_APP_DISABLE_LEADS && {
    path: '/buy/leads/campaigns/:groupId/:campaignId',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(BuyCampaignDetail),
  },
  !REACT_APP_DISABLE_LEADS && {
    path: '/buy/leads/campaigns/:campaignId/geo',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(BuyLeadsCampaignDetailAllLocations),
  },
  {
    path: '/buy/clicks/clicks',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(BuyClicksClicks),
  },
  {
    path: '/buy/clicks/create-campaign',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(BuyClicksCreateCampaign),
  },
  // {
  //   path: '/buy/clicks/categories',
  //   exact: true,
  //   component: withProtectedRoute(() => {
  //     return checkLoggedIn();
  //   })(BuyClicksCategories),
  // },
  {
    path: '/buy/clicks/edit-campaign',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(BuyClicksEditCampaign),
  },
  {
    path: '/buy/clicks/edit-geography',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(BuyClicksEditGeography),
  },
  {
    path: '/buy/clicks/edit-specifications',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(BuyClicksEditSpecifications),
  },
  {
    path: '/buy/clicks/duplicate-campaign/:campaignId',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(DuplicateCampaign),
  },
  {
    path: '/buy/clicks/duplicate-group/:groupId',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(DuplicateGroup),
  },
  {
    path: '/buy/clicks/campaigns',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(BuyCampaigns),
  },
  {
    path: '/buy/clicks/campaigns/:groupId',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(BuyCampaignGroup),
  },
  {
    path: '/buy/clicks/campaigns/:groupId/:campaignId',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(BuyCampaignDetail),
  },
  // {
  //   path: '/buy/clicks/categories/:categoryId',
  //   exact: true,
  //   component: withProtectedRoute(() => {
  //     return checkLoggedIn();
  //   })(BuyClicksCategoryCampaigns),
  // },
  // {
  //   path: '/buy/clicks/categories/:categoryId/campaigns/locations',
  //   exact: true,
  //   component: withProtectedRoute(() => {
  //     return checkLoggedIn();
  //   })(BuyClicksCampaignLocations),
  // },
  !REACT_APP_DISABLE_LEADS && {
    path: '/buy/leads/import',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(BuyCampaignsImport),
  },
  {
    path: '/buy/clicks/import',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(BuyCampaignsImport),
  },
  {
    path: '/buy/clicks/overview',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(BuyOverview),
  },
  // {
  //   path: '/buy/clicks/analytics',
  //   exact: true,
  //   component: withProtectedRoute(() => {
  //     return checkLoggedIn();
  //   })(BuyAnalytics),
  // },
  {
    path: '/buy/clicks/welcome',
    exact: true,
    component: BuyClicksWelcomePage,
  },
  !REACT_APP_DISABLE_LEADS && {
    path: '/buy/leads/welcome',
    exact: true,
    component: BuyLeadsWelcomePage,
  },
  !REACT_APP_DISABLE_SELL && {
    path: '/sell/leads/welcome',
    exact: true,
    component: SellLeadsWelcomePage,
  },
  !REACT_APP_DISABLE_SELL && {
    path: '/sell/leads/overview',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(SellLeadsOverview),
  },
  !REACT_APP_DISABLE_SELL && {
    path: '/sell/leads/campaigns',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(SellLeadsCampaigns),
  },
  !REACT_APP_DISABLE_SELL && {
    path: '/sell/leads/campaigns/:campaignId',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(SellLeadsCampaignDetail),
  },
  // {
  //   path: '/sell/leads/duplicate-campaign',
  //   exact: true,
  //   component: withProtectedRoute(() => {
  //     return checkLoggedIn();
  //   })(DuplicateCampaign),
  // },
  {
    path: '/setup-billing',
    exact: true,
    component: withProtectedRoute(() => {
      return checkHasNoBuyCampaigns();
    })(SetupBilling),
  },
  {
    path: '/setup-billing/active',
    exact: true,
    component: SetupBillingActive,
  },
  {
    path: '/account/overview',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(AccountOverview),
  },
  {
    path: '/account/info/edit',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(AccountEdit),
  },
  {
    path: '/account/billing',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(AccountBilling),
  },
  {
    path: '/account/billing/edit',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(AccountBillingEdit),
  },
  {
    path: '/account/day-parting/edit',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(AccountDayPartingEdit),
  },
  {
    path: '/account/budget/edit',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(AccountBudgetEdit),
  },
  {
    path: '/finances/overview',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(FinancesOverview),
  },
  {
    path: '/finances/transactions',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(FinancesPurchases),
  },
  {
    path: '/finances/statements',
    exact: true,
    component: FinancesStatements,
  },
  {
    path: '/analytics',
    exact: true,
    component: withProtectedRoute(() => {
      return checkLoggedIn();
    })(TableauStats),
  },
  {
    path: '/_dev/create-buyer',
    exact: true,
    component: DevCreateBuyer,
  },
  {
    path: '/terms',
    exact: true,
    component: TermsOfUse,
  },
  {
    path: '/about',
    exact: true,
    component: AboutUs,
  },
  {
    path: '/privacy',
    exact: true,
    component: PrivacyPolicy,
  },
  {
    path: '/agreement',
    exact: true,
    component: PrivacyAgreement,
  },
  {
    path: '/vulnerability-policy',
    exact: true,
    component: VulnerabilityPolicy,
  },
  {
    path: '/cookie-policy',
    exact: true,
    component: CookiePolicy,
  },
  {
    path: '/accessibility-statement',
    exact: true,
    component: AccessibilityStatement,
  },
  {
    path: '/info',
    exact: true,
    component: GetHelp,
  },
  {
    component: NotFound,
  },
].filter(Boolean);

const Routes = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        {routes.map(({ component: Component, ...route }) => {
          return (
            <Route {...route} key={route.path ?? '*'}>
              <Component />
            </Route>
          );
        })}
      </Switch>
    </Router>
  );
};

export default Routes;
